// Mobile Logo
.logo-mobile {
  img {
    width: 60px;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

// Desktop Logo
.logo-desktop {
  display: none;

  img {
    width: 60px;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.navbar {
  z-index: 100;
  padding-top: 5px;
  padding-bottom: 5px;

  @include media-breakpoint-up(md) {
    padding-left: 60px;
    padding-right: 50px;
    border: unset
  }

  .navbar-toggler {
    .navbar-toggler-icon {
      background-image: url('../img/burger-icon.png');
      background-repeat: no-repeat;
      width: 30px;
    }
  }

  .navbar-nav {
    .nav-link {
      text-transform: uppercase;
      font-family: $font-family-syne;
      font-size: 14px;
      font-weight: 400;
      color: #1e1f1a;
    }
  }
}

#active.navbar .navbar-nav .nav-link {
  color: #FFFFFF;
}

/*Navbar Transparent*/
.navbar-transparent {
  position: absolute;
  width: 100%;

  &.fixed-top {
    position: fixed;
    background-color: $white;

    .logo-desktop {
      img {
        width: 60px;
      }
    }
  }

  &.navbar-white {
    // position: relative;
    background-color: $white;

    // border-bottom: 1px solid $border-color;
    &.fixed-top {
      position: fixed;
    }

  }
}



.navbar-expand-md {
  .navbar-collapse {
    @include media-breakpoint-up(md) {
      justify-content: space-between;

      .navbar-nav-left {
        width: 33%;
      }

      .navbar-nav-right {
        width: 33%;
        justify-content: flex-end;
      }
    }
  }
}

#active .navbar-toggler-icon {
  background-image: url('../img/burger-icon-white.png');
  background-repeat: no-repeat;
  width: 30px;
}
a.nav-link{
  position: relative;

}
a.nav-link.active:before {
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 90%;
  height: 2px;
  background: #7f9cbb;
  content: "";
  margin-left: 5%;
}
