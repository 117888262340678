// Slider
.swiper-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slider-bg-holder {
  height: 100vh;
  width: 100%;
}


.banner-content-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}

.banner-content-container.different {
  justify-content: flex-start;
}

.full-logo img {
  width: 200px;
  margin-bottom: 30px;
}

.content-block {
  width: 100%;
  max-width: 500px;
  color: #fff;
}

.content-block span {
  font-family: impact;
  font-size: 50px;
  display: block;
}

.fat-border {
  width: 80px;
  height: 6px;
  background: #fff;
  margin-bottom: 40px;
}

.content-block p {
  font-size: 27px;
}

.action a {
  display: inline-block;
  border: 1px solid #fff;
  padding: 15px 60px;
  margin-top: 40px;
}

.image-block.different {
  position: absolute;
  right: 0;
  margin-right: -200px;
}


.gorgeous, .fabulous, .best {
  background-position: center top;
}

.looking {
  background-position: 64%;
}

.perfect {
  background-position: 74%;
}

.rejuvenate {
  background-position: 58%;
}

.banner-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 100px;
  font-family: $font-family-syne;
  position: relative;

  .title {
    font-size: 30px;
    color: white;
    font-weight: 400;
    text-align: center;
    letter-spacing: 10px;
    @include media-breakpoint-up(md) {
      font-size: 60px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 85px;
      letter-spacing: 16px;
    }
  }

  .sub-title {
    color: #fff;
  }


  &.content-left {
    width: 50%;
  }

  &.content-right {
    width: 50%;
    margin-left: 50%;
  }
}

p.sub-title a {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;

  &:hover {
    text-decoration: none;
  }

  @include media-breakpoint-up(xl) {
    font-size: 35px;
  }
}

.swiper-pagination-bullets {
  bottom: 50px !important;
  left: auto !important;
  right: 50px !important;
  text-align: right !important;
  font-family: $font-family-roboto;
  font-size: 14px;
  height: 0;

  .swiper-pagination-bullet {
    background-color: transparent !important;
    color: $dark !important;
    opacity: .6 !important;
    margin: 0 !important;
    width: 30px;

    &.swiper-pagination-bullet-active {
      color: $dark;
      opacity: 1 !important;
    }
  }
}

.white .swiper-pagination-bullets .swiper-pagination-bullet {
  color: #fff !important;
}

//.swiper-pagination:before{
//  position: absolute;
//  right:  210px;
//  top: 10px;
//  width: calc(95% - 210px);
//  content: "";
//  border-top: 2px solid #fff;
//
//}
.banner-text-image {
  width: 90%;
  @include media-breakpoint-up(xl) {
    width: 72%;
  }

  img {
    width: 100%;
  }
}

.white .banner-content {
  padding-bottom: 0;
  justify-content: center;
}

.footer-logo {
  position: absolute;
  right: 47px;
  bottom: 25px;
  width: 44px;
  opacity: .6;
}

.footer-logo img {
  width: 100%;
}


.store-btn-hodlder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  bottom: 90px;
  left: 95px;

  img {
    max-width: 220px;
  }
}

.rw-word {
  display: inline;
  margin-left: 279px;
  text-indent: -264px;
}

.rw-word1 span {
  position: absolute;
  color: rgb(5, 81, 88);
  opacity: 0;
  -webkit-animation: word;
  -o-animation: word;
  animation: word;
  -webkit-animation-duration: 6s;
  -moz-animation-duration: 6s;
  -o-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.rw-word1 span:nth-child(2) {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;
  color: goldenrod;
}

@keyframes word {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  5% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  17% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  20% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.banner-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-bottom: 100px;
  font-family: "Syne", sans-serif;
  position: relative;
  align-items: flex-start;
}

.author-title {
  font-size: 55px;
  text-transform: lowercase;
  color: #fff;
}

.faces {
  padding: 0;
  list-style: none;
  margin: -50px 0;
  height: calc(100% + 100px);
  position: relative;
  transition: all 1s cubic-bezier(0, 0.53, 0.34, 1.5);
}

.face {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: rgba(255,255,255,.2);
  position: relative;
  margin-bottom: 23px;
  cursor: pointer;

}

.icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon i {
  font-size: 30px;
  color: #fff;
}


.bubble {
  position: absolute;
  left: 100%;
  width: 400px;
  top: 0;
  margin-left: 20px;
  font-size: 23px;
  text-transform: lowercase;
  font-weight: 700;
  background: rgba(255,255,255,.3);
  height: 142px;
  margin-top: -100px;
  border-radius: 23px 23px 20px 0;
  padding: 10px;
  pointer-events: none;
  transition: all .3s cubic-bezier(0, 0.53, 0.34, 1.5);
  opacity: 0;
  transform: scale(0);
  transform-origin: bottom left;
  color: #fff;
}

.face.has-bubble-open {
  border: 2px solid #ddd;
}
.face.has-bubble-open .bubble {
  opacity: 1;
  transform: scale(1);
  border: 2px solid #ddd;
}
