// Techonolgy page
.technology-page {
  padding-bottom: 50px;



  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
  .content-thumb{
    @include media-breakpoint-up(md) {
      height: 650px;
      background-position: center 36%;
      background-size: cover;
    }
    @include media-breakpoint-up(lg) {
      height: calc(100vh - 120px);
      background-position: center;
      background-size: cover;
    }

  }

  .app-store {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    .icons-hodlder {
      display: flex;
      align-items: center;

      img {
        &:not(:first-child) {
          margin-left: 40px;
        }
      }
    }
  }
}
.technology-list li {
  background: #eee;
  padding: 2px 11px;
  margin-right: 3px;
  color: #7f9cbb;
  font-size: 13px;
  border-radius: 4px;
}
